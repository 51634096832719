import apiClient from 'api/apiClient'

import { ISignInResponse } from './types'

export interface ISignInParams {
  email: string
  password: string
}

const signIn = async ({ email, password }: ISignInParams) => {
  return await apiClient
    .post('sessions', {
      json: {
        email,
        password
      }
    })
    .json<ISignInResponse>()
}

export { signIn }
