import RequestException from 'errors/RequestException'
import { QueryClient } from 'react-query'

import getApiErrorHandler from './errorHandler'

const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        onError: (err) => {
          getApiErrorHandler()(err as RequestException)
        }
      },
      mutations: {
        retry: 0,
        onError: (err) => {
          getApiErrorHandler()(err as RequestException)
        }
      }
    }
  })
}

export default createQueryClient
