import { ReactNode } from 'react'
import styled from 'styled-components'
import theme from 'theme'

export interface IStackProps {
  children: ReactNode

  /**
   * @default 0.8
   */
  space?: number
  /**
   * @default 'horizontal'
   */
  orientation?: 'horizontal' | 'vertical'
}

const Stack = styled.div<IStackProps>`
  display: grid;
  grid-auto-flow: ${({ orientation }) =>
    orientation === 'horizontal' ? 'column' : 'row'};
  gap: ${({ space }) => `${space ?? 0}rem`};
`

Stack.defaultProps = {
  orientation: 'horizontal',
  space: theme.spacing[1]
}

export default Stack
