import * as Sentry from '@sentry/react'

import { getSentryDSN, getSentryEnvironment } from './utils'

import { getAdminReleaseVersion } from 'utils'

const initializeSentry = () => {
  const sentryEnvironment = getSentryEnvironment()

  Sentry.init({
    enabled: Boolean(sentryEnvironment),
    dsn: getSentryDSN(),
    environment: getSentryEnvironment(),
    release: getAdminReleaseVersion()
  })
}

export default initializeSentry
