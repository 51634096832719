import * as yup from 'yup'

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Provide your new password')
    .min(8, 'Password must be at least 8 characters long')
    .max(255, 'Password can be no longer than 255 characters.')
})

export default schema
