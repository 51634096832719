const getSentryDSN = () => {
  // eslint-disable-next-line no-undef
  const sentryDSN = process.env.REACT_APP_SENTRY_DSN

  if (!sentryDSN) {
    throw new Error('The REACT_APP_SENTRY_DSN is not set!')
  }

  return sentryDSN
}

const getSentryEnvironment = () => {
  // eslint-disable-next-line no-undef
  const hostEnvironment = process.env.REACT_APP_HOST_ENVIRONMENT

  return hostEnvironment
}

export { getSentryDSN, getSentryEnvironment }
