import { EApiErrorTypes } from 'api/types'

export interface IRequestException {
  type?: EApiErrorTypes
  status?: number
  message: string
}

class RequestException extends Error {
  type?: EApiErrorTypes
  status?: number

  constructor(message: string, type?: EApiErrorTypes, status?: number) {
    super(message)
    this.type = type
    this.status = status
    this.name = 'RequestException'
  }
}

export default RequestException
