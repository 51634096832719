import createQueryClient from 'api/createQueryClient'
import ToastConfiguration from 'components/ToastNotification/config'
import { AuthContextProvider } from 'context/AuthContext'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import GlobalStyles from 'theme/global'

import Routing from './Routing'

import 'theme/fonts.css'

const client = createQueryClient()

const App = () => {
  return (
    <>
      <GlobalStyles />
      <ToastConfiguration />
      <QueryClientProvider client={client}>
        <AuthContextProvider>
          <Routing />
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </AuthContextProvider>
      </QueryClientProvider>
    </>
  )
}

export default App
