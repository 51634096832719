import { EApiErrorTypes, IErrorResponse } from 'api/types'
import RequestException from 'errors/RequestException'
import ky from 'ky'
import { MutationOptions, useMutation } from 'react-query'
import { getApiUrl } from 'utils'

export interface IRequestPasswordResetVariables {
  email: string
}

export interface IRequestPasswordResetParams {
  variables: IRequestPasswordResetVariables
}

const requestPasswordReset = async ({
  variables
}: IRequestPasswordResetParams) => {
  const { email } = variables

  if (!email) {
    throw new Error('[requestPasswordReset]: No email passed!')
  }

  return await ky.post(`${getApiUrl()}/admin/admins/request-password-reset`, {
    json: { email },
    hooks: {
      afterResponse: [
        async (_request, options, response) => {
          if (response.ok) {
            /**
             * Our current API endpoints currently does not return anything on DELETE requests
             */
            return response
          }

          if (response.status < 500) {
            const res: IErrorResponse = await response.json()

            throw new RequestException(res.message, res.type, response.status)
          }

          throw new RequestException(
            'Server Error',
            EApiErrorTypes.InternalServerError,
            response.status
          )
        }
      ]
    }
  })
}

const useRequestPasswordResetMutation = (
  options: MutationOptions<
    Response,
    RequestException,
    IRequestPasswordResetVariables
  > = {}
) =>
  useMutation<Response, RequestException, IRequestPasswordResetVariables>(
    async (variables) => await requestPasswordReset({ variables }),
    options
  )

export { useRequestPasswordResetMutation }
