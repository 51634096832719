import styled, { css } from 'styled-components'
import theme from 'theme'

export interface IEyeIconWrapProps {
  active: boolean
  disabled?: boolean
}

export const StyledEyeIconWrap = styled.div<IEyeIconWrapProps>`
  transition: color ${theme.animations.duration}
    ${theme.animations.timingFunction};
  ${({ disabled, active }) =>
    disabled
      ? css`
          color: ${theme.colors.grey[400]};
        `
      : css`
          color: ${active ? theme.colors.grey[700] : theme.colors.grey[500]};
          &:hover {
            color: ${theme.colors.grey[700]};
          }
        `};
`
