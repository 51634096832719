import CrossIcon from 'components/icons/Cross'
import InfoIcon from 'components/icons/Info'
import SuccessIcon from 'components/icons/Success'
import WarningIcon from 'components/icons/Warning'
import { FC } from 'react'

import {
  StyledToastNotificationContainer,
  StyledToastNotificationIconWrapper,
  StyledToastNotificationMessage
} from './styled'
import { TToastNotificationType } from './types'

const iconMap = {
  info: <InfoIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  error: <WarningIcon />
}

export interface IToastNotificationProps {
  type: TToastNotificationType
  message: string
}

const ToastNotification: FC<IToastNotificationProps> = ({ type, message }) => {
  return (
    <StyledToastNotificationContainer>
      <StyledToastNotificationIconWrapper type={type}>
        {iconMap[type]}
      </StyledToastNotificationIconWrapper>
      <StyledToastNotificationMessage>{message}</StyledToastNotificationMessage>
      <CrossIcon />
    </StyledToastNotificationContainer>
  )
}

export default ToastNotification
