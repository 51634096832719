import InputField, { IInputFieldProps } from 'components/inputs/InputField'
import { forwardRef, useState } from 'react'

import EyeIcon from '../../icons/Eye'
import StyledInputButton from '../InputButton'

import { StyledEyeIconWrap } from './styled'

export type IPasswordInputProps = Omit<IInputFieldProps, 'type'>

const PasswordInput = forwardRef<HTMLInputElement, IPasswordInputProps>(
  ({ disabled, name, label, ...props }, ref) => {
    const [shown, setShown] = useState(false)

    return (
      <InputField
        label={label}
        name={name}
        ref={ref}
        type={shown ? 'text' : 'password'}
        suffixContent={
          <StyledInputButton
            type='button'
            tabIndex={-1}
            onClick={() => setShown(!shown)}
            disabled={disabled}
            aria-label='show password'
          >
            <StyledEyeIconWrap active={shown} disabled={disabled}>
              <EyeIcon />
            </StyledEyeIconWrap>
          </StyledInputButton>
        }
        disabled={disabled}
        {...props}
      />
    )
  }
)

export default PasswordInput
