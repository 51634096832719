import { ButtonHTMLAttributes, FC, ReactElement } from 'react'
import theme from 'theme'

import {
  StyledButton,
  StyledButtonIconContainer,
  StyledButtonContent,
  StyledButtonIconWrapper
} from './styled'
import { TButtonVariants, TButtonSizes, TColorKeysWithShades } from './types'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * @default false
   */
  isFluid?: boolean
  /**
   * @default 'tertiary'
   */
  variant?: TButtonVariants
  /**
   * @default 'base'
   */
  size?: TButtonSizes
  /**
   * @default 'blue'
   */
  color?: TColorKeysWithShades
  /**
   * Should be set only for non-primary buttons
   */
  textColor?: string
  icon?: ReactElement
}

const Button: FC<IButtonProps> = ({
  children,
  isFluid = false,
  variant = 'tertiary',
  size = 'base',
  color = 'blue',
  icon,
  ...restProps
}) => {
  const textColor =
    restProps.textColor ?? variant === 'primary'
      ? theme.colors.white
      : theme.colors.grey[900]

  return (
    <StyledButton
      isFluid={isFluid}
      variant={variant}
      color={color}
      textColor={textColor}
      size={size}
      {...restProps}
    >
      {icon ? (
        <StyledButtonIconContainer>
          <StyledButtonIconWrapper>{icon}</StyledButtonIconWrapper>
          <StyledButtonContent>{children}</StyledButtonContent>
        </StyledButtonIconContainer>
      ) : (
        children
      )}
    </StyledButton>
  )
}

export default Button
