import apiClient from 'api/apiClient'
import { IProtectedEndpointParams } from 'api/types'

const logOut = async ({ token }: IProtectedEndpointParams) => {
  return await apiClient.delete('sessions', {
    headers: {
      Authorization: token
    }
  })
}

export { logOut }
