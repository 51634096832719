import { FC } from 'react'

const WarningIcon: FC = () => {
  return (
    <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Warning</title>
      <path
        d='M9.112 11.245a1.11 1.11 0 11-2.222 0 1.11 1.11 0 012.222 0zM8.924 3.924v3.663a.924.924 0 11-1.849 0V3.924a.925.925 0 011.849 0z'
        fill='currentColor'
      />
      <path
        d='M8 0a8 8 0 100 16A8 8 0 008 0zm4.651 12.651a6.58 6.58 0 01-9.342.04 6.576 6.576 0 01.04-9.342 6.58 6.58 0 019.342-.04 6.576 6.576 0 01-.04 9.342z'
        fill='currentColor'
      />
      <path
        d='M8 0a8 8 0 100 16A8 8 0 008 0zm4.651 12.651a6.58 6.58 0 01-9.342.04 6.576 6.576 0 01.04-9.342 6.58 6.58 0 019.342-.04 6.576 6.576 0 01-.04 9.342z'
        fill='currentColor'
      />
    </svg>
  )
}

export default WarningIcon
