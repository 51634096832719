import { FC, ReactNode } from 'react'

import {
  StyledHeader,
  StyledHeaderLeftContentContainer,
  StyledHeaderCenterContentContainer,
  StyledHeaderRightContentContainer
} from './styled'

export interface IHeaderProps {
  leftContent?: ReactNode
  centerContent?: ReactNode
  rightContent?: ReactNode
}

const Header: FC<IHeaderProps> = ({
  leftContent,
  centerContent,
  rightContent
}) => {
  return (
    <StyledHeader>
      <StyledHeaderLeftContentContainer>
        {leftContent}
      </StyledHeaderLeftContentContainer>
      <StyledHeaderCenterContentContainer>
        {centerContent}
      </StyledHeaderCenterContentContainer>
      <StyledHeaderRightContentContainer>
        {rightContent}
      </StyledHeaderRightContentContainer>
    </StyledHeader>
  )
}

export default Header
