import { FC } from 'react'
import theme from 'theme'

const LogoIcon: FC = () => {
  return (
    <svg width='80' height='80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Logo</title>
      <g filter='url(#filter0_d)'>
        <rect
          x='22'
          y='22'
          width='48'
          height='48'
          rx='10.667'
          fill={theme.colors.blue[500]}
        />
      </g>
      <path
        d='M34.518 52.298c0 .567-.118.68-1.184.794V54h7.227v-.908c-1.066-.113-1.185-.227-1.185-.794v-5.334h.948c1.185 0 2.275.454 2.488 2.27h.948v-5.9h-.948c-.213 1.815-1.303 2.269-2.488 2.269h-.948v-6.355h2.489c1.658 0 2.843.908 3.317 2.837h.948l-.119-3.971H33.334v.907c1.067.114 1.185.227 1.185.795v12.482zM53.01 38H50l-4.574 13.844c-.308.93-.592 1.021-1.895 1.248V54h5.687v-.908c-1.896-.227-2.465-.454-2.014-1.815l.592-1.816h4.787l.83 2.156c.45 1.158-.048 1.362-.996 1.475V54H60v-.908c-.948-.227-1.114-.272-1.422-1.021L53.01 38zm-3.034 4.766h.048l2.038 5.333h-3.84l1.754-5.333z'
        fill={theme.colors.white}
      />
      <defs>
        <filter
          id='filter0_d'
          x='.667'
          y='.667'
          width='90.667'
          height='90.667'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='10.667' />
          <feColorMatrix values='0 0 0 0 0.227451 0 0 0 0 0.384314 0 0 0 0 0.94902 0 0 0 0.16 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default LogoIcon
