import RequestException from 'errors/RequestException'
import ky from 'ky'
import { getApiUrl } from 'utils'

import { EApiErrorTypes, IErrorResponse } from './types'

const apiClient = ky.extend({
  prefixUrl: `${getApiUrl()}/admin`,
  /**
   * The amount of retries will be handled by `react-query`
   */
  retry: 0,
  hooks: {
    afterResponse: [
      async (_request, options, response) => {
        if (response.ok) {
          /**
           * Our current API endpoints currently does not return anything on DELETE requests
           */
          return options.method === 'DELETE'
            ? undefined
            : response.headers.get('Content-Type') === 'application/json'
            ? await response.json()
            : undefined
        }

        if (response.status < 500) {
          const res: IErrorResponse = await response.json()

          throw new RequestException(res.message, res.type, response.status)
        }

        throw new RequestException(
          'Server Error',
          EApiErrorTypes.InternalServerError,
          response.status
        )
      }
    ]
  }
})

export default apiClient
