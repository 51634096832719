import styled from 'styled-components'
import theme from 'theme'

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8rem;
  padding: 0 3.2rem;
  z-index: 200;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(8rem, max-content) auto minmax(
      8rem,
      max-content
    );
  align-items: center;

  background: ${theme.colors.white};
  box-shadow: inset 0 -0.1rem 0 ${theme.colors.grey[400]};
`

const StyledHeaderLeftContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`
const StyledHeaderCenterContentContainer = styled.div`
  display: flex;
  justify-content: center;
`
const StyledHeaderRightContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export {
  StyledHeader,
  StyledHeaderLeftContentContainer,
  StyledHeaderCenterContentContainer,
  StyledHeaderRightContentContainer
}
