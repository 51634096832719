import { FC } from 'react'

import { LoadingRing, AnimatedPart } from './styled'

export interface ILoadingProps {
  color?: string
  /**
   * @default '4rem'
   */
  size?: string
}

const Loading: FC<ILoadingProps> = ({ color, size = '4rem' }) => {
  return (
    <LoadingRing data-testid='loading-indicator' size={size}>
      <AnimatedPart color={color} size={size} />
      <AnimatedPart color={color} size={size} />
      <AnimatedPart color={color} size={size} />
      <AnimatedPart color={color} size={size} />
    </LoadingRing>
  )
}

export default Loading
