import styled, { css } from 'styled-components'
import theme from 'theme'

export interface IStyledPhoneInputWraperProps {
  disabled?: boolean
  hasError?: boolean
}

export const StyledPhoneInputWrapper = styled.div<IStyledPhoneInputWraperProps>`
  .react-tel-input {
    font-family: ${theme.fonts.book};
    .phone-input {
      width: 100%;
      height: 4.8rem;
      padding: 1.6rem 1.6rem 1.6rem 7.5rem;
      ${theme.typography.subtitle1}
      border-radius: 0.8rem;
      border-width: 0.1rem;
      transition: box-shadow ${theme.animations.duration}
        ${theme.animations.timingFunction};
      color: ${theme.colors.grey[900]};
      ${({ hasError }) =>
        hasError
          ? css`
              background-color: ${theme.colors.orange[100]};
              border-color: ${theme.colors.orange[300]};
              &:focus {
                box-shadow: 0 0 0 0.4rem ${theme.colors.orange[300]};
                border-color: ${theme.colors.orange[500]};
                & + .flag-dropdown {
                  border-color: ${theme.colors.orange[500]};
                }
              }
            `
          : css`
              background-color: ${theme.colors.grey[100]};
              border-color: ${theme.colors.grey[100]};
              &:focus {
                box-shadow: 0 0 0 0.4rem ${theme.colors.blue[300]};
                border-color: ${theme.colors.blue[500]};
                & + .flag-dropdown {
                  border-color: ${theme.colors.blue[500]};
                }
              }
            `}

      &::placeholder {
        color: ${theme.colors.grey[500]};
      }
      &:disabled {
        border-color: ${theme.colors.grey[200]};
        color: ${theme.colors.grey[400]};
        &::placeholder {
          color: ${theme.colors.grey[400]};
        }
      }
    }

    ${({ disabled, hasError }) =>
      !disabled
        ? css`
            &:hover {
              .phone-input,
              .flag-dropdown {
                border-color: ${hasError
                  ? theme.colors.orange[500]
                  : theme.colors.blue[500]};
              }
            }
          `
        : null}

    .flag-dropdown,
    .selected-flag {
      width: 6.7rem;
      border-radius: 0.8rem 0 0 0.8rem;
    }

    .flag-dropdown {
      border-right: none;
      border-left: 0.1rem solid transparent;
      border-top: 0.1rem solid transparent;
      border-bottom: 0.1rem solid transparent;
      height: 4.8rem;
      ${({ disabled, hasError }) =>
        disabled
          ? css`
              border-color: ${theme.colors.grey[200]};
              &:hover {
                background-color: ${theme.colors.grey[100]};
              }
            `
          : css`
              ${hasError
                ? css`
                    border-color: ${theme.colors.orange[300]};
                    background-color: ${theme.colors.orange[100]};
                    .open {
                      border-radius: 0.8rem 0 0 0.8rem;
                      background-color: ${theme.colors.orange[300]};
                    }
                    &:hover {
                      background-color: ${theme.colors.orange[300]};
                    }
                    &:focus {
                      border-color: ${theme.colors.orange[500]};
                    }
                  `
                : css`
                    background-color: ${theme.colors.grey[100]};
                    .open {
                      border-radius: 0.8rem 0 0 0.8rem;
                      background-color: transparent;
                    }
                    &:hover {
                      border-color: ${theme.colors.blue[500]};
                      background-color: ${theme.colors.grey[200]};
                    }
                  `}
            `}
    }

    .selected-flag {
      padding: 0;
      border: none;
      box-sizing: border-box;
      padding-left: 1.6rem;
      background-color: transparent;
    }
    .phone-input-dropdown {
      margin-top: 0.1rem;
      margin-bottom: 0;
      height: 19.2rem;
      background: ${theme.colors.white};
      border: 0.1rem solid ${theme.colors.grey[400]};
      border-radius: 1.2rem;
      box-shadow: 0 1rem 2rem rgba(156, 158, 172, 0.1);
    }

    .flag {
      width: 2.2rem;
    }

    .country-list {
      .country {
        padding: 1.6rem 1.6rem 1.6rem 5.6rem;
        color: ${theme.colors.grey[900]};
        ${theme.typography.subtitle1}
        &:hover {
          background-color: ${theme.colors.grey[100]};
        }
        &.highlight {
          background-color: ${theme.colors.blue[100]};
          color: ${theme.colors.blue[500]};
        }
        .flag {
          left: 1.6rem;
          top: 1.2rem;
        }
        .dial-code {
          color: ${theme.colors.grey[500]};
        }
      }
    }

    .arrow {
      border-top: 0.8rem solid ${theme.colors.grey[500]};
      border-right: 0.4rem solid transparent;
      border-left: 0.4rem solid transparent;
      top: 40%;
      margin: 0;
      &.up {
        border-bottom: 0.8rem solid ${theme.colors.grey[500]};
        border-top: none;
      }
    }
  }
`
