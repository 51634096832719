import Stack from 'components/Stack'

import {
  StyledAuthPageH1,
  StyledInfoText,
  StyledLinkText
} from '../../../styled'

const ForgotPasswordSuccess = ({
  submittedEmail
}: {
  submittedEmail: string
}) => {
  return (
    <Stack space={4} orientation='vertical'>
      <StyledAuthPageH1>Check your inbox</StyledAuthPageH1>

      <StyledLinkText>
        Link to reset your password has been sent to
        <span> {submittedEmail}</span>
      </StyledLinkText>

      <StyledInfoText>
        You’ll receive this email within 5 minutes. Be sure to check your spam
        folder, too.
      </StyledInfoText>
    </Stack>
  )
}

export default ForgotPasswordSuccess
