import { showErrorToast } from 'components/ToastNotification/utils'

import RequestException from '../errors/RequestException'

import { EApiErrorTypes } from './types'

type TErrorMap = {
  [key in EApiErrorTypes]?: string | (() => void)
} & {
  default?: string
}

const defaultErrorMessageMap = {
  [EApiErrorTypes.AccountBlocked]:
    'Your account has been locked, please contact the administrator.',
  [EApiErrorTypes.CannotDelete]:
    'Cannot be deleted. Please delete associated entities first.',
  [EApiErrorTypes.Conflict]: 'Entity already exists.',
  [EApiErrorTypes.Forbidden]: 'Access forbidden.',
  [EApiErrorTypes.NotFound]: 'Not found.',
  [EApiErrorTypes.InvalidBody]:
    'Invalid request. Please double-check provided data in the form.',
  [EApiErrorTypes.InvalidCredentials]: 'Email and password does not match.',
  [EApiErrorTypes.InternalServerError]:
    'Service is down. Please try again later.',
  [EApiErrorTypes.Unauthorized]: 'You are not authorized. Please sign in.',
  [EApiErrorTypes.UserNotFound]: 'User not found.',
  default: 'Request failed.'
} as const

const getErrorHandle = (error: RequestException, errorMap: TErrorMap) => {
  return error.type
    ? errorMap?.[error.type] ??
        errorMap?.default ??
        defaultErrorMessageMap[error.type] ??
        defaultErrorMessageMap.default
    : defaultErrorMessageMap.default
}

export interface IApiErrorHandlerOptions {
  errorMap: TErrorMap

  onServerError?: () => void
}

const getApiErrorHandler =
  (options: IApiErrorHandlerOptions = { errorMap: defaultErrorMessageMap }) =>
  (error: RequestException) => {
    const errorHandle = getErrorHandle(error, options.errorMap)

    if (typeof errorHandle === 'string') {
      showErrorToast(errorHandle)
    } else if (typeof errorHandle === 'function') {
      errorHandle()
    }
  }

export default getApiErrorHandler
