import styled from 'styled-components'
import theme from 'theme'

const StyledInputButton = styled.button`
  height: 2.4rem;
  width: 2.4rem;
  transition: color ${theme.animations.duration}
    ${theme.animations.timingFunction};
  color: ${theme.colors.grey[900]};
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${theme.colors.grey[300]};
  }
  &:not(:last-child) {
    margin-right: ${theme.spacing[2]}rem;
  }
`

export default StyledInputButton
