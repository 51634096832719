import LoadingPage from 'components/layout/LoadingPage'
import { useAuthContext } from 'context/AuthContext'
import ForgotPasswordPage from 'pages/auth/ForgotPassword'
import ResetPasswordPage from 'pages/auth/ResetPassword'
import SignInPage from 'pages/auth/SignIn'
import SignUpPage from 'pages/auth/SignUp'
import { FC, lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import routes from 'utils/routes'

const ProtectedRoutes = lazy(async () => await import('./ProtectedRoutes'))

const Routing: FC = () => {
  const { user, isAuthenticating } = useAuthContext()

  if (isAuthenticating) {
    return <LoadingPage />
  }

  return (
    <Router>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {user ? <ProtectedRoutes /> : null}

          <Route exact path={routes.signIn}>
            <SignInPage />
          </Route>
          <Route exact path={routes.signUp}>
            <SignUpPage />
          </Route>

          <Route exact path={routes.forgotPassword}>
            <ForgotPasswordPage />
          </Route>

          <Route exact path={routes.resetPassword}>
            <ResetPasswordPage />
          </Route>

          <Redirect to={routes.signIn} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routing
