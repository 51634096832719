import styled from 'styled-components'

export interface IShiftByProps {
  /**
   * Unit: rems
   *
   * @default 0
   */
  x?: number
  /**
   * Unit: rems
   *
   * @default 0
   */
  y?: number
}

const ShiftBy = styled.div<IShiftByProps>`
  transform: ${({ x = 0, y = 0 }) => `translate3d(${x}rem, ${y}rem, 0)`};
`

export default ShiftBy
