import styled from 'styled-components'
import theme from 'theme'

import { TToastNotificationType } from './types'

const typeColorMap = {
  info: theme.colors.blue[500],
  success: theme.colors.green[500],
  warning: theme.colors.orange[500],
  error: theme.colors.red[500]
}

const StyledToastNotificationContainer = styled.div`
  ${theme.typography.subtitle1}

  display: grid;
  grid-template-columns: 5.2rem auto 3rem;
  grid-gap: 1.6rem;
  align-items: center;

  box-sizing: border-box;
  min-height: 6rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  border-radius: 1.2rem;

  color: ${theme.colors.white};
  background-color: ${theme.colors.grey[900]};
`

interface IStyledToastNotificationIconWrapperProps {
  type: TToastNotificationType
}

const StyledToastNotificationIconWrapper = styled.span<IStyledToastNotificationIconWrapperProps>`
  display: grid;
  place-items: center;

  height: 5.2rem;
  width: 5.2rem;
  border-radius: 1rem;
  background-color: ${({ type }) => typeColorMap[type]};

  > svg {
    transform: scale(1.2);
  }
`

const StyledToastNotificationMessage = styled.p``

export {
  StyledToastNotificationContainer,
  StyledToastNotificationIconWrapper,
  StyledToastNotificationMessage
}
