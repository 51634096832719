import { differenceInYears } from 'date-fns'

// eslint-disable-next-line import/extensions
import packageJson from '../../package.json'

const noop = () => {}

const delay = async (ms = 1000) => {
  const promise = new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, ms)
  })

  return await promise
}

const getAdminReleaseVersion = () => {
  return packageJson.version
}

const getApiUrl = () => {
  const apiUrl = process.env.REACT_APP_API_URL

  if (!apiUrl) {
    throw new Error('The API URL is not defined!')
  }

  return apiUrl
}

const getGoogleMapsAPIKey = () => {
  // eslint-disable-next-line no-undef
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

  if (!key) {
    throw new Error('The GOOGLE_MAPS_API_KEY is not set!')
  }

  return key
}

const calculateAge = (dateOfBirth: string) => {
  return differenceInYears(new Date(), new Date(dateOfBirth))
}

const getUniqueValues = <T>(values: T[]): T[] => [...new Set(values)]

const downloadFile = (fileUrl: string, fileName: string) => {
  const linkElement = document.createElement('a')
  linkElement.setAttribute('href', fileUrl)
  linkElement.setAttribute('download', fileName)
  linkElement.click()

  linkElement.remove()
}

const getFullNameInitials = (fullName: string) => {
  return fullName
    .split(' ')
    .map((namePart) => namePart.charAt(0))
    .join('')
}

export {
  noop,
  delay,
  getAdminReleaseVersion,
  getApiUrl,
  getGoogleMapsAPIKey,
  calculateAge,
  getUniqueValues,
  downloadFile,
  getFullNameInitials
}
