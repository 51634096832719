import initializeSentry from 'config/sentry/initialize'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import App from './App'

initializeSentry()

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)
