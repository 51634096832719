import { StyledInputWrapper } from 'components/inputs/InputField/styled'
import { FC } from 'react'
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'

import ErrorMessage from '../ErrorMessage'
import HelperMessage from '../HelperMessage'
import StyledLabel from '../Label'

import { StyledPhoneInputWrapper } from './styled'

export interface IPhoneInputProps extends PhoneInputProps {
  /** Input name */
  name: string
  /** onChange function to be passed to conect with forms */
  onChange: (value: string) => void
  /** Label text */
  label?: string
  /** Helper text */
  helperMessage?: string
  /** Validation error message */
  errorMessage?: string
  /** Specify whether the input is required */
  required?: boolean
  /** Disables input */
  disabled?: boolean
}

const PhoneInput: FC<IPhoneInputProps> = ({
  helperMessage,
  errorMessage,
  label,
  disabled,
  required,
  name,
  ...restProps
}: IPhoneInputProps) => {
  return (
    <StyledInputWrapper>
      <StyledLabel disabled={disabled} htmlFor={name}>
        {label}
      </StyledLabel>
      <StyledPhoneInputWrapper
        hasError={Boolean(errorMessage)}
        disabled={disabled}
      >
        <ReactPhoneInput
          inputProps={{
            id: name,
            name,
            required
          }}
          country='us'
          dropdownClass='phone-input-dropdown'
          containerClass='phone-input-container'
          inputClass='phone-input'
          buttonClass='phone-input-button'
          searchClass='phone-input-search-class'
          disabled={disabled}
          {...restProps}
        />
      </StyledPhoneInputWrapper>

      {!errorMessage && helperMessage ? (
        <HelperMessage>{helperMessage}</HelperMessage>
      ) : null}
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </StyledInputWrapper>
  )
}

export default PhoneInput
