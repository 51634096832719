import styled from 'styled-components'
import theme from 'theme'

const StyledLoadingPageLayout = styled.div`
  min-height: 100vh;

  display: grid;
  place-items: center;

  background-color: ${theme.colors.grey[100]};
`

const StyledLoadingIconHolder = styled.div`
  text-align: center;
`

const StyledLoadingMessage = styled.p`
  ${theme.typography.title1}
`

export {
  StyledLoadingPageLayout,
  StyledLoadingIconHolder,
  StyledLoadingMessage
}
