import { FC } from 'react'

const SuccessIcon: FC = () => {
  return (
    <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Success</title>
      <circle
        cx='12'
        cy='12'
        r='8'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.572 9.374a.5.5 0 00-.706.028l-3.73 4.042-1.838-1.696a.5.5 0 00-.678.735l2.205 2.035a.5.5 0 00.707-.028l4.069-4.41a.5.5 0 00-.029-.706z'
        fill='currentColor'
      />
      <path
        d='M11.136 13.444l-.34.367.368.34.34-.368-.368-.34zm.396 1.046l.367.339-.367-.34zm4.069-4.41l-.368-.339.367.34zm-.368-.339l.678-.735a1 1 0 00-1.413.057l.735.678zm-3.73 4.042l3.73-4.042-.735-.678-3.73 4.042.735.678zM8.96 12.116l1.838 1.695.678-.735-1.837-1.695-.679.735zm0 0l.679-.735a1 1 0 00-1.414.057l.735.678zm0 0l-.735-.678a1 1 0 00.057 1.413l.678-.735zm2.205 2.034L8.96 12.116l-.678.735 2.205 2.034.678-.735zm0 0l-.678.735a1 1 0 001.413-.056l-.735-.679zm4.07-4.409l-4.07 4.41.735.678 4.069-4.41-.735-.678zm0 0l.734.678a1 1 0 00-.057-1.413l-.678.735z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SuccessIcon
