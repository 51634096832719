import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import theme from 'theme'

const GlobalStyles = createGlobalStyle`
  ${normalize}

  html {
    /* to make 1 rem equal 10px */
    font-size: 62.5%;
    box-sizing: border-box;
  }

  body {
    margin: 0;

    font-size: 1.2rem;
    font-family: 'Geomanist Book', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background-color: ${theme.colors.grey[100]};
    #root {
      height: 100%;
    }
  }

  button {
    line-height: normal;
  }

  .Toastify__toast-container {
    min-width: 56.8rem;
  }

  .Toastify__toast {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  .Toastify__toast-body {
    width: 100%;
  }
`

export default GlobalStyles
