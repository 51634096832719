import { css } from 'styled-components'

import colors from './colors'
import { fontFamilies, fontSizes } from './fonts'

const base = css`
  font-family: ${fontFamilies.book};
  font-style: normal;
  font-weight: normal;
`

const typography = {
  base,
  metadata: css`
    ${base}

    font-size: ${fontSizes.metadata}rem;
  `,
  body: css`
    ${base}

    font-size: ${fontSizes.body}rem;
    line-height: normal;
  `,
  subtitle1: css`
    ${base}

    font-size: ${fontSizes.subtitle1}rem;
    line-height: 1.8rem;
  `,
  subtitle2: css`
    ${base}

    font-size: ${fontSizes.subtitle2}rem;
    line-height: 2.4rem;
  `,
  title1: css`
    ${base}

    font-size: ${fontSizes.title1}rem;
    line-height: 2.8rem;
  `,
  title2: css`
    ${base}

    font-size: ${fontSizes.title2}rem;
    line-height: 3.6rem;
  `,
  sectionTitle1: css`
    ${base}

    font-size: ${fontSizes.sectionTitle1}rem;
    line-height: 4rem;
  `,
  sectionTitle2: css`
    ${base}

    font-size: ${fontSizes.sectionTitle2}rem;
    line-height: 5.2rem;
  `,
  sectionTitle3: css`
    ${base}

    font-size: ${fontSizes.sectionTitle3}rem;
    line-height: 7.6rem;
  `,
  link: css`
    color: ${colors.blue[500]};
    text-decoration: none;

    transform: color 200ms ease-in-out;
    will-change: color;

    &:hover,
    &:focus {
      color: ${colors.blue[700]};
      text-decoration: underline;
    }

    &:active {
      color: ${colors.blue[900]};
    }
  `
}

export default typography
