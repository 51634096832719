import styled from 'styled-components'
import theme from 'theme'

import { StyledHeader } from '../Header/styled'

export const AuthPageLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.grey[100]};
`

export const AuthPageHeader = styled(StyledHeader)`
  background-color: transparent;
  box-shadow: none;

  @media (max-width: 600px) {
    position: absolute;
    top: 12%;
  }
`

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
`
