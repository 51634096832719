export interface IProtectedEndpointParams {
  token: string
}

export enum EApiErrorTypes {
  AccountBlocked = 'E_ACCOUNT_BLOCKED',
  CannotDelete = 'E_CANNOT_DELETE',
  Conflict = 'E_CONFLICT',
  Forbidden = 'E_FORBIDDEN',
  NotFound = 'E_NOT_FOUND',
  InvalidBody = 'E_INVALID_BODY',
  InvalidCredentials = 'E_INVALID_CREDENTIALS',
  InternalServerError = 'E_INTERNAL_SERVER_ERROR',
  Unauthorized = 'E_UNAUTHORIZED',
  UserNotFound = 'E_USER_NOT_FOUND'
}

export interface IErrorResponse extends Error {
  type: EApiErrorTypes
  message: string
  errors?: any
}

export interface ILocationPoint {
  lat: number
  lng: number
}

export interface IBaseEntity {
  id: number
  createdAt: string
  updatedAt: string
}

export type TCrowdinTranslationType = 'machine' | 'standard' | 'high'
export type TCrowdinLanguage =
  | 'de'
  | 'fr'
  | 'nl'
  | 'it'
  | 'pt-BR'
  | 'es-MX'
  | 'pl'
  | 'tr'
