import { forwardRef } from 'react'
import theme from 'theme'

import SuccessIcon from '../../icons/Success'
import WarningIcon from '../../icons/Warning'
import StyledInputIconHolder from '../IconHolder'
import InputField, { IInputFieldProps } from '../InputField'

const TextInput = forwardRef<HTMLInputElement, IInputFieldProps>(
  ({ valid, errorMessage, ...props }, ref) => {
    return (
      <InputField
        ref={ref}
        valid={valid}
        errorMessage={errorMessage}
        suffixContent={
          <>
            {errorMessage && (
              <StyledInputIconHolder color={theme.colors.orange[500]}>
                <WarningIcon />
              </StyledInputIconHolder>
            )}
            {valid && (
              <StyledInputIconHolder color={theme.colors.green[500]}>
                <SuccessIcon />
              </StyledInputIconHolder>
            )}
            {props?.suffixContent}
          </>
        }
        {...props}
      />
    )
  }
)

export default TextInput
