import { animated } from 'react-spring'
import styled from 'styled-components'
import theme from 'theme'

const StyledErrorMessage = styled(animated.div)`
  position: absolute;
  bottom: -0.8rem;

  font-family: ${theme.fonts.book}rem;
  font-size: ${theme.fontSizes.metadata}rem;
  line-height: 1.6rem;
  color: ${theme.colors.orange[500]};
`

StyledErrorMessage.defaultProps = {
  role: 'alert'
}

export default StyledErrorMessage
