import styled from 'styled-components'
import theme from 'theme'

interface ICardProps {
  padding?: string
  /**
   * @default false
   */
  noShadow?: boolean
}

const Card = styled.div<ICardProps>`
  padding: ${({ padding }) => padding};
  background-color: ${theme.colors.white};
  border-radius: 1.2rem;
  box-shadow: ${({ noShadow }) =>
    noShadow ? 'none' : '0 1rem 2rem rgba(156, 158, 172, 0.1)'};
`

Card.defaultProps = {
  padding: '4rem 6.8rem',
  noShadow: false
}

export default Card
