import { forwardRef, InputHTMLAttributes, ReactElement, ReactNode } from 'react'
import { useSpring } from 'react-spring'

import ErrorMessage from '../ErrorMessage'
import HelperMessage from '../HelperMessage'
import Label from '../Label'

import {
  StyledInput,
  StyledInputContentWrapper,
  StyledInputWrapper,
  StyledInputExtraContent
} from './styled'

export interface IInputFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  /** Input name. Also used as input id */
  name: string

  /** Input field label. Can be a string or a Label component */
  label?: string | ReactElement

  /** Helper text */
  helperMessage?: string

  /** Error message. Displays as error message underneath the input.  */
  errorMessage?: string

  /** Specify whether input is valid. Only used to display Success icon. */
  valid?: boolean

  /** Allow to pass aditional children e.g. Dropdown */
  children?: ReactElement[] | ReactNode

  /** Prefix content e.g. Icon */
  prefixIcon?: ReactElement | null

  /** Suffix content e.g. dropdown controls */
  suffixContent?: ReactElement | undefined | null

  /** Specify whether input has white background */
  hasWhiteBg?: boolean
}

const InputField = forwardRef<HTMLInputElement, IInputFieldProps>(
  (
    {
      label,
      helperMessage,
      name,
      errorMessage,
      children,
      suffixContent,
      prefixIcon,
      hasWhiteBg,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const { opacity, y } = useSpring({
      opacity: errorMessage ? 1 : 0,
      y: errorMessage ? 0 : -10,
      config: {
        tension: 120,
        friction: 14
      }
    })

    return (
      <StyledInputWrapper>
        {label ? (
          typeof label === 'string' ? (
            <Label disabled={disabled} htmlFor={name}>
              {label}
            </Label>
          ) : (
            label
          )
        ) : null}
        <StyledInputContentWrapper>
          {prefixIcon ? (
            <StyledInputExtraContent side='left'>
              {prefixIcon}
            </StyledInputExtraContent>
          ) : null}

          {suffixContent ? (
            <StyledInputExtraContent side='right'>
              {suffixContent}
            </StyledInputExtraContent>
          ) : null}

          <StyledInput
            hasPrefix={Boolean(prefixIcon)}
            id={name}
            name={name}
            ref={ref}
            hasError={Boolean(errorMessage)}
            hasWhiteBg={Boolean(hasWhiteBg)}
            disabled={disabled}
            {...restProps}
          />
          {children}
        </StyledInputContentWrapper>
        {helperMessage && !errorMessage ? (
          <HelperMessage disabled={disabled}>{helperMessage}</HelperMessage>
        ) : null}
        {errorMessage ? (
          <ErrorMessage
            style={{
              opacity,
              transform: y.interpolate((y) => `translate3d(0, ${y}px, 0)`)
            }}
          >
            {errorMessage}
          </ErrorMessage>
        ) : null}
      </StyledInputWrapper>
    )
  }
)

export default InputField
