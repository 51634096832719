import Card from 'components/Card'
import Loading from 'components/Loading'
import Stack from 'components/Stack'
import LogoIcon from 'components/icons/Logo'
import { FC } from 'react'
import theme from 'theme'

import Header from '../Header'

import {
  StyledLoadingPageLayout,
  StyledLoadingIconHolder,
  StyledLoadingMessage
} from './styled'

const LoadingPage: FC = () => {
  return (
    <>
      <Header leftContent={<LogoIcon />} />
      <StyledLoadingPageLayout data-testid='loading-page-content'>
        <Card>
          <Stack orientation='vertical' space={1.6}>
            <StyledLoadingIconHolder>
              <Loading size='6rem' color={theme.colors.blue[500]} />
            </StyledLoadingIconHolder>
            <StyledLoadingMessage>
              Initializing... please wait.
            </StyledLoadingMessage>
          </Stack>
        </Card>
      </StyledLoadingPageLayout>
    </>
  )
}

export default LoadingPage
