import colors from './colors'
import { fontFamilies, fontSizes } from './fonts'
import typography from './typography'

const theme = {
  colors,
  typography,
  fontSizes,
  fonts: fontFamilies,
  spacing: [0.5, 0.8, 1, 1.6, 2.2, 2.4, 3.0, 3.2, 4.0, 4.8, 6.8] as const,
  animations: {
    duration: '200ms',
    timingFunction: 'ease-in-out'
  } as const
}

export default theme
