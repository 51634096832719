import { TArticleStatus } from 'api/articles/types'
import { TQnAStatus } from 'api/q-and-a/types'

const routes = {
  /**
   * Public Pages
   */
  signIn: '/sign-in',
  signUp: '/sign-up',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',

  /**
   * Protected Pages
   */
  home: '/',

  exportData: '/export-data',
  exportDataHistory: '/export-data/history',

  userList: '/users',
  userReports: '/users/reports',
  userFeedback: '/users/feedback',
  userDetail: '/users/:userId',
  userDetailWithSection: '/users/:userId/:section',

  adminList: '/admins',
  adminNew: '/admins/new',
  adminDetail: '/admins/:adminId',
  adminDetailWithSection: '/admins/:adminId/:section',

  newsList: '/news/:status(draft|published|archived)',
  newsTagList: '/news/tags',
  newsCategories: '/news/categories',
  newsListByCategory: '/news/categories/:categoryId',
  newsNewArticle: '/news/new',
  newsDetailNoStatus: '/news/:articleId',
  newsDetail: '/news/:status(draft|published)/:articleId',
  newsArchivedDetail: '/news/archived/:articleId',

  centerList: '/centers',
  centerTypeList: '/centers/types',
  centerNew: '/centers/new',
  centerDetail: '/centers/:centerId',

  trialList: '/trials/clinical/',
  trialNew: '/trials/clinical/new',
  trialDetail: '/trials/clinical/:trialId',
  trialVirtualList: '/trials/virtual/',
  trialVirtualDetail: '/trials/virtual/:trialId/:section',

  questionList: '/q-and-a/:status(draft|published|archived)',
  questionInReviewList: '/q-and-a/to-review',
  questionSuggestionsList: '/q-and-a/suggestions',
  questionCategoryList: '/q-and-a/categories',
  // for creating a new Q&A Question based from suggestion send suggestionId as a search query parameter
  questionNew: '/q-and-a/new',
  questionArchivedDetail: '/q-and-a/:status(archived)/:QnAId',
  questionDetail: '/q-and-a/:status(draft|published)/:QnAId',
  questionDetailNoStatus: '/q-and-a/:QnAId',

  profile: '/profile',
  profileWithSection: '/profile/:section'

  // TODO: more routes
} as const

export interface IMyProfileRouteParams {
  adminId: string
  section: string
}

export interface ICenterDetailRouteParams {
  centerId: string
}
export interface IAppUserDetailRouteParams {
  userId: string
  section: string
}

export interface IArticleListRouteParams {
  status: TArticleStatus
}

export interface IArticleListByCategoryListRouteParams {
  categoryId: string
}

export interface ITrialDetailRouteParams {
  trialId: string
  section: string
}

export interface IArticleDetailRouteParams {
  articleId: string
  status: TArticleStatus
}

export interface IArchivedArticleRouteParams {
  articleId: string
}

export interface IAdminDetailRouteParams {
  adminId: string
  section: string
}

export interface IQnAListRouteParams {
  status: TQnAStatus
}

export interface IQnADetailRouteParams {
  QnAId: string
  status: TQnAStatus
}

export default routes
