import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Please provide your full name')
    .min(2, 'Your name is too short')
    .max(30, 'Your name is too long'),
  password: yup
    .string()
    .required('Please provide your password')
    .min(8, 'Password must be at least 8 characters long')
    .max(255, 'Password can be no longer than 255 characters.'),
  phone: yup.string().required('Please provide your phone number')
})

export default schema
