import styled, { css } from 'styled-components'
import theme from 'theme'

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-bottom: ${theme.spacing[3]}rem;
`

export interface IStyledInputProps {
  /** Specify error state for error styles */
  hasError: boolean

  /** Specify  whether input has a prefix so extra padding can be applied */
  hasPrefix?: boolean

  /** Specify whether input has white background */
  hasWhiteBg?: boolean
}

export const StyledInput = styled.input<IStyledInputProps>`
  border-radius: 0.8rem;
  width: 100%;
  height: 4.8rem;
  padding: ${theme.spacing[3]}rem;
  ${({ hasPrefix }) =>
    hasPrefix
      ? css`
          padding-left: ${theme.spacing[3] + 3.6}rem;
        `
      : null}
  font-size: ${theme.fontSizes.subtitle1}rem;
  font-family: ${theme.fonts.book};
  color: ${theme.colors.grey[900]};
  line-height: 1;
  border-style: solid;
  border-width: 0.1rem;
  box-sizing: border-box;
  ${({ hasError }) =>
    hasError
      ? css`
          background-color: ${theme.colors.orange[100]};
          border-color: ${theme.colors.orange[300]};
          &:hover {
            border-color: ${theme.colors.orange[500]};
          }
          &:focus {
            box-shadow: 0 0 0 0.4rem ${theme.colors.orange[300]};
            border-color: ${theme.colors.orange[500]};
          }
        `
      : css`
          background-color: ${theme.colors.grey[100]};
          border-color: ${theme.colors.grey[100]};
          &:hover {
            border-color: ${theme.colors.blue[500]};
          }
          &:focus {
            box-shadow: 0 0 0 0.4rem ${theme.colors.blue[300]};
            border-color: ${theme.colors.blue[500]};
          }
        `}
  ${({ hasWhiteBg }) =>
    hasWhiteBg
      ? css`
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.grey[300]};
          &:hover {
            border-color: ${theme.colors.blue[500]};
          }
          &:focus {
            box-shadow: 0 0 0 0.4rem ${theme.colors.blue[300]};
            border-color: ${theme.colors.blue[500]};
          }
        `
      : null}
  &::placeholder {
    color: ${theme.colors.grey[500]};
  }
  &:disabled {
    border-color: ${theme.colors.grey[200]};
    color: ${theme.colors.grey[400]};
    &::placeholder {
      color: ${theme.colors.grey[400]};
    }
  }
  outline: none;
  transition: box-shadow ${theme.animations.duration}
      ${theme.animations.timingFunction},
    border-color ${theme.animations.duration} ${theme.animations.timingFunction};
`

export interface IStyledInputExtraContentProps {
  side: 'left' | 'right'
}
/* transform: translate3d(0, 50%, 0); */
export const StyledInputExtraContent = styled.div<IStyledInputExtraContentProps>`
  position: absolute;
  z-index: 10;
  height: 2.4rem;
  top: 1.2rem;
  ${({ side }) =>
    css`
      ${side}: ${theme.spacing[3]}rem;
    `};
`

export const StyledInputContentWrapper = styled.div`
  position: relative;
  height: 4.8rem;
`
