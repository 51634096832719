import LogoIcon from 'components/icons/Logo'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import routes from 'utils/routes'

import * as S from './styled'

const AuthPageLayout: FC = ({ children }) => {
  return (
    <S.AuthPageLayout>
      <S.AuthPageHeader>
        <Link to={routes.signIn}>
          <LogoIcon />
        </Link>
      </S.AuthPageHeader>

      <S.MainContent>{children}</S.MainContent>
    </S.AuthPageLayout>
  )
}

export default AuthPageLayout
