import styled from 'styled-components'
import theme from 'theme'

const StyledHelperMessage = styled.div<{ disabled?: boolean }>`
  font-family: ${theme.fonts.book}rem;
  font-size: ${theme.fontSizes.metadata}rem;
  line-height: 1.6rem;
  color: ${(props) =>
    props.disabled ? theme.colors.grey[400] : theme.colors.grey[500]};
  margin-top: 0.6rem;
`

export default StyledHelperMessage
