import styled from 'styled-components'
import theme from 'theme'

export interface IStyledIconHolderProps {
  color?: string
}

const StyledInputIconHolder = styled.div<IStyledIconHolderProps>`
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color ?? theme.colors.grey[900]};
`

export default StyledInputIconHolder
