import { yupResolver } from '@hookform/resolvers/yup'
import { useAdminOnboardMutation } from 'api/admins/onboard'
import Button from 'components/Button'
import Card from 'components/Card'
import Loading from 'components/Loading'
import ShiftBy from 'components/ShiftBy'
import Stack from 'components/Stack'
import {
  showErrorToast,
  showSuccessToast
} from 'components/ToastNotification/utils'
import PasswordInput from 'components/inputs/PasswordInput'
import PhoneInput from 'components/inputs/PhoneInput'
import TextInput from 'components/inputs/TextInput'
import AuthPageLayout from 'components/layout/AuthPageLayout'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import theme from 'theme'
import routes from 'utils/routes'

import {
  StyledAuthPageCardContent,
  StyledAuthPageH1,
  StyledAuthPageHelpText,
  StyledLinkText
} from '../styled'

import schema from './schema'

interface ISignUpFormValues {
  name: string
  password: string
  phone: string
}

const CreateAccountPage = () => {
  const history = useHistory()
  const location = useLocation()

  const { email, inviteCode } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      email: searchParams.get('email'),
      inviteCode: searchParams.get('code')
    }
  }, [location.search])

  const { mutate: adminOnboard } = useAdminOnboardMutation({
    onSuccess: (adminUser) => {
      showSuccessToast(
        `Registration has been successfully completed ${
          adminUser.name ?? 'Admin'
        }. Please sign in.`
      )
      const encodedEmail = encodeURIComponent(adminUser.email)

      history.push(`${routes.signIn}?email=${encodedEmail}`)
    }
  })

  const { register, errors, control, handleSubmit, formState } =
    useForm<ISignUpFormValues>({
      resolver: yupResolver(schema),
      defaultValues: {
        name: '',
        phone: '',
        password: ''
      }
    })

  useEffect(() => {
    if (!email || !inviteCode) {
      showErrorToast(
        'You need to be invited first in order to complete registration.'
      )
      history.replace(routes.signIn)
    }
  }, [email, history, inviteCode])

  const onSubmit = (formValues: ISignUpFormValues) => {
    if (!email || !inviteCode) return

    adminOnboard({
      ...formValues,
      email,
      inviteCode
    })
  }

  return (
    <AuthPageLayout>
      <Card style={{ padding: '3rem', marginBottom: '6rem' }}>
        <StyledAuthPageCardContent>
          <Stack orientation='vertical' space={4}>
            <div>
              <StyledAuthPageH1 id='create-account-heading'>
                Create an account
              </StyledAuthPageH1>
              <ShiftBy y={0.3}>
                <StyledLinkText>You are signing up as {email}</StyledLinkText>
              </ShiftBy>
            </div>
            <StyledAuthPageHelpText>
              <p>To finish a registration we need from you:</p>
            </StyledAuthPageHelpText>
          </Stack>
          <form
            aria-labelledby='create-account-heading'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack orientation='vertical' space={4}>
              <TextInput
                ref={register}
                name='name'
                label='Full name *'
                placeholder='Your full name'
                errorMessage={errors.name?.message}
              />
              <PasswordInput
                ref={register}
                name='password'
                label='Password *'
                placeholder='Your password'
                errorMessage={errors.password?.message}
              />
              <Controller
                control={control}
                name='phone'
                render={({ name, value, onChange }) => (
                  <PhoneInput
                    name={name}
                    label='Phone number *'
                    value={value}
                    onChange={onChange}
                    errorMessage={errors.phone?.message}
                  />
                )}
              />
              <Button
                disabled={formState.isSubmitting}
                type='submit'
                variant='primary'
              >
                {formState.isSubmitting ? (
                  <Loading color={theme.colors.white} size='2rem' />
                ) : (
                  'Create account'
                )}
              </Button>
            </Stack>
          </form>
        </StyledAuthPageCardContent>
      </Card>
      <StyledAuthPageHelpText>
        <p>
          Already have an account? <Link to={routes.signIn}>Log in</Link>{' '}
          instead.
        </p>
      </StyledAuthPageHelpText>
    </AuthPageLayout>
  )
}

export default CreateAccountPage
