import apiClient from 'api/apiClient'
import { IRequestException } from 'errors/RequestException'
import { MutationOptions, useMutation } from 'react-query'

import { IAdminOnboardMutationVariables, IAdminOnboardResponse } from './types'

export interface IAdminOnboardParams {
  variables: IAdminOnboardMutationVariables
}

const adminOnboard = async ({ variables }: IAdminOnboardParams) => {
  return await apiClient
    .post('admins/onboard', {
      json: variables
    })
    .json<IAdminOnboardResponse>()
}

const useAdminOnboardMutation = (
  options: MutationOptions<
    IAdminOnboardResponse,
    IRequestException,
    IAdminOnboardMutationVariables
  > = {}
) => {
  return useMutation<
    IAdminOnboardResponse,
    IRequestException,
    IAdminOnboardMutationVariables
  >(async (variables) => await adminOnboard({ variables }), options)
}

export { adminOnboard, useAdminOnboardMutation }
