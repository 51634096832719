import { EApiErrorTypes, IErrorResponse } from 'api/types'
import RequestException from 'errors/RequestException'
import ky from 'ky'
import { MutationOptions, useMutation } from 'react-query'
import { getApiUrl } from 'utils'

export interface IConfirmPasswordResetVariables {
  newPassword: string
  token: string
}

interface IConfirmPasswordResetParams {
  variables: IConfirmPasswordResetVariables
}

const confirmPasswordReset = async ({
  variables
}: IConfirmPasswordResetParams) => {
  const { newPassword, token } = variables

  if (!token) {
    throw new Error('[confirmPasswordRequest]: No token provided!')
  }

  if (!newPassword) {
    throw new Error('[confirmPasswordRequest]: newPassword not provided!')
  }

  return await ky.post(`${getApiUrl()}/admin/admins/confirm-password-reset`, {
    json: { newPassword, token },
    hooks: {
      afterResponse: [
        async (_request, options, response) => {
          if (response.ok) {
            /**
             * Our current API endpoints currently does not return anything on DELETE requests
             */
            return response
          }

          if (response.status < 500) {
            const res: IErrorResponse = await response.json()

            throw new RequestException(res.message, res.type, response.status)
          }

          throw new RequestException(
            'Server Error',
            EApiErrorTypes.InternalServerError,
            response.status
          )
        }
      ]
    }
  })
}

const useConfirmPasswordResetMutation = (
  options: MutationOptions<
    Response,
    RequestException,
    IConfirmPasswordResetVariables
  > = {}
) =>
  useMutation<Response, RequestException, IConfirmPasswordResetVariables>(
    async (variables) => await confirmPasswordReset({ variables }),
    options
  )

export { useConfirmPasswordResetMutation }
