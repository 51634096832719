import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'theme'

const StyledAuthPageCardContent = styled.div`
  width: 35rem;
  display: grid;
  grid-auto-flow: row;
  gap: 4rem;
  justify-content: stretch;
`

const StyledAuthPageH1 = styled.h1`
  justify-self: center;
  ${theme.typography.sectionTitle1}
  color: ${theme.colors.grey[900]};
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
`

const StyledForgotPasswordLink = styled(Link)`
  ${theme.typography.link}
  justify-self: center;
  color: ${theme.colors.blue[500]};
  text-decoration: none;
  font-size: ${theme.fontSizes.subtitle1}rem;
`

const StyledAuthPageHelpText = styled.div`
  font-size: ${theme.fontSizes.body}rem;
  text-align: center;

  p {
    margin: 0;
    ${theme.typography.body}
    color: ${theme.colors.grey[900]};
  }
  a {
    ${theme.typography.link}
    text-decoration: none;
  }
`
const StyledCardText = styled.p`
  ${theme.typography.body}
  text-align: center;
`

const StyledLinkText = styled(StyledCardText)`
  margin: 0;
  color: ${theme.colors.grey[500]};
  span {
    color: ${theme.colors.grey[900]};
  }
`

const StyledInfoText = styled(StyledCardText)`
  color: ${theme.colors.grey[700]};
`

export {
  StyledAuthPageCardContent,
  StyledAuthPageH1,
  StyledForgotPasswordLink,
  StyledAuthPageHelpText,
  StyledInfoText,
  StyledLinkText
}
