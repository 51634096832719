import { FC } from 'react'

const CrossIcon: FC = () => {
  return (
    <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Cross</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.657 6.343a1 1 0 000 1.414L10.899 12l-4.242 4.243a1 1 0 001.414 1.414l4.243-4.243 4.242 4.243a1 1 0 001.415-1.414L13.728 12l4.243-4.243a1 1 0 00-1.415-1.414l-4.242 4.243L8.07 6.343a1 1 0 00-1.414 0z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CrossIcon
