import { toast } from 'react-toastify'

import ToastNotification from '.'

const showSuccessToast = (message: string) =>
  toast(<ToastNotification type='success' message={message} />)

const showInfoToast = (message: string) =>
  toast(<ToastNotification type='info' message={message} />)

const showWarningToast = (message: string) =>
  toast(<ToastNotification type='warning' message={message} />)

const showErrorToast = (message: string) =>
  toast(<ToastNotification type='error' message={message} />)

export { showSuccessToast, showInfoToast, showWarningToast, showErrorToast }
