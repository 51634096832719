import { useMyProfileQuery } from 'api/admins/myProfile'
import { IAdmin } from 'api/admins/types'
import { logOut } from 'api/sessions/logOut'
import { ISignInParams, signIn } from 'api/sessions/signIn'
import ToastNotification from 'components/ToastNotification'
import useCookie from 'hooks/useCookie'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

export interface IAuthContext {
  isAuthenticating: boolean
  sessionToken: string | null
  user: IAdmin | null

  handleSignIn: (credentials: ISignInParams) => Promise<void>
  handleLogOut: () => Promise<void>
}

const AuthContext = createContext<IAuthContext | null>(null)
AuthContext.displayName = 'AuthContext'

const AuthContextProvider: FC = ({ children }) => {
  // needs to be manually managed in state so we are able to clear it on log out
  const [myProfile, setMyProfile] = useState<IAdmin | null>(null)
  const [sessionToken, setSessionToken, deleteSessionToken] =
    useCookie('sessionToken')

  const queryClient = useQueryClient()
  const { isFetching } = useMyProfileQuery({
    token: sessionToken,
    retry: 0,
    staleTime: sessionToken ? Infinity : undefined,
    onSuccess: (profileData) => {
      setMyProfile(profileData)
    },
    onError: (err) => {
      switch (err.status) {
        case 500:
          toast(
            <ToastNotification
              type='error'
              message='Service is down. Please try again later.'
            />
          )
          break
        default:
          toast(
            <ToastNotification
              type='error'
              message='Invalid session token. Logging out.'
            />
          )
          deleteSessionToken()
      }
    }
  })

  const handleSignIn = useCallback(
    async (credentials: ISignInParams) => {
      try {
        const response = await signIn(credentials)

        setSessionToken(response.sessionToken)
      } catch (err) {
        console.error('[AuthContextProvider::handleSignIn]', err)

        // rethrow for further processing in UI (show toast, ...)
        throw err
      }
    },
    [setSessionToken]
  )

  const handleLogOut = useCallback(async () => {
    if (!sessionToken) {
      setMyProfile(null)

      return
    }

    try {
      await logOut({ token: sessionToken })

      deleteSessionToken()
      setMyProfile(null)
      queryClient.clear()
    } catch (err) {
      console.error('[AuthContextProvider::handleLogOut]', err)

      // rethrow for further processing in UI (show toast, ...)
      throw err
    }
  }, [deleteSessionToken, queryClient, sessionToken])

  const contextValue = useMemo(() => {
    return {
      isAuthenticating: isFetching,
      user: myProfile ?? null,
      sessionToken,
      handleSignIn,
      handleLogOut
    }
  }, [handleLogOut, handleSignIn, isFetching, myProfile, sessionToken])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

const useAuthContext = () => {
  const contextValue = useContext(AuthContext)

  if (!contextValue) {
    throw new Error(
      'useAuthContext must be used within AuthContextProvider scope'
    )
  }

  return contextValue
}

export { AuthContext, AuthContextProvider, useAuthContext }
