const fontFamilies = {
  book: "'Geomanist Book', sans-serif",
  regular: "'Geomanist Regular', sans-serif"
} as const

const fontSizes = {
  metadata: 1.2,
  body: 1.4,
  subtitle1: 1.6,
  subtitle2: 1.8,
  title1: 2.0,
  title2: 2.8,
  sectionTitle1: 3.2,
  sectionTitle2: 4.2,
  sectionTitle3: 6.8
} as const

export { fontFamilies, fontSizes }
