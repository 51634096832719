import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import Loading from 'components/Loading'
import Stack from 'components/Stack'
import TextInput from 'components/inputs/TextInput'
import { useForm } from 'react-hook-form'
import theme from 'theme'

import { StyledAuthPageH1 } from '../../../styled'

import schema from './schema'

export interface IForgotPasswordFormValues {
  email: string
}

const ForgotPasswordForm = ({
  onSubmit
}: {
  onSubmit: (values: IForgotPasswordFormValues) => void
}) => {
  const { register, handleSubmit, formState, errors } =
    useForm<IForgotPasswordFormValues>({
      defaultValues: {
        email: ''
      },
      mode: 'onBlur',
      resolver: yupResolver(schema)
    })

  return (
    <form aria-label='forget-password-form' onSubmit={handleSubmit(onSubmit)}>
      <Stack space={4} orientation='vertical'>
        <StyledAuthPageH1>Forgot password</StyledAuthPageH1>
        <TextInput
          ref={register}
          name='email'
          label='Email address'
          type='email'
          placeholder='Your email address'
          errorMessage={errors?.email?.message}
        />
        <Button
          disabled={formState.isSubmitting}
          type='submit'
          variant='primary'
        >
          {formState.isSubmitting ? (
            <Loading color={theme.colors.white} size='2rem' />
          ) : (
            'Send me a reset password link'
          )}
        </Button>
      </Stack>
    </form>
  )
}

export default ForgotPasswordForm
