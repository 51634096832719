import { StyledDropdownMenu } from 'components/inputs/DropdownMenu/styled'
import styled, { css } from 'styled-components'
import theme from 'theme'

import { TButtonVariants, TButtonSizes, TColorKeysWithShades } from './types'

export interface IStyledButtonProps {
  variant: TButtonVariants
  color: TColorKeysWithShades
  textColor: string

  size?: TButtonSizes
  isFluid?: boolean
}

type TButtonVariantProps = Omit<IStyledButtonProps, 'variant'>

const buttonVariants = {
  primary: ({ color, textColor }: TButtonVariantProps) => {
    const colorObj = theme.colors[color]

    return css`
      color: ${textColor};
      background-color: ${colorObj[500]};
      border: none;

      &:hover,
      &:focus {
        background-color: ${colorObj[700]};
      }
      &:focus {
        box-shadow: 0 0 0 0.4rem ${colorObj[300]};
      }
      &:active {
        background-color: ${colorObj[900]};
      }

      &:disabled {
        background-color: ${theme.colors.grey[500]};
        color: ${theme.colors.black};
      }
    `
  },
  secondary: ({ color, textColor }: TButtonVariantProps) => {
    const colorObj = theme.colors[color]

    return css`
      color: ${textColor};
      background-color: transparent;
      border: 0.1rem solid ${theme.colors.grey[300]};

      &:hover,
      &:focus {
        color: ${colorObj[500]};
        background-color: ${colorObj[100]};
        border-color: ${colorObj[500]};
      }
      &:focus {
        box-shadow: 0 0 0 0.4rem ${colorObj[300]};
      }
      &:active {
        color: ${colorObj[500]};
        background-color: ${colorObj[300]};
        border-color: ${colorObj[500]};
      }

      &:disabled {
        color: ${theme.colors.grey[500]};
        background-color: ${theme.colors.grey[200]};
        border: 0.1rem solid ${theme.colors.grey[500]};
      }
    `
  },
  tertiary: ({ color, textColor }: TButtonVariantProps) => {
    const colorObj = theme.colors[color]

    return css`
      color: ${textColor};
      background-color: transparent;
      border: none;

      &:hover,
      &:focus {
        color: ${colorObj[500]};
        background-color: ${colorObj[100]};
      }
      &:focus {
        box-shadow: 0 0 0 0.4rem ${colorObj[300]};
      }
      &:active {
        color: ${colorObj[500]};
        background-color: ${colorObj[300]};
      }

      &:disabled {
        color: ${theme.colors.grey[500]};
        background-color: ${theme.colors.grey[200]};
      }
    `
  }
}
const buttonSizes = {
  base: css`
    padding: ${theme.spacing[1]}rem ${theme.spacing[3]}rem;
    min-width: 10.8rem;
    min-height: 4.8rem;
  `,
  small: css`
    padding: ${theme.spacing[1]}rem;
  `
}

const StyledButton = styled.button<IStyledButtonProps>`
  ${theme.typography.body}

  box-sizing: border-box;
  width: ${({ isFluid = false }) => (isFluid ? '100%' : 'auto')};
  border-radius: 0.8rem;

  transition: color ${theme.animations.duration}
      ${theme.animations.timingFunction},
    background-color ${theme.animations.duration}
      ${theme.animations.timingFunction},
    border-color ${theme.animations.duration} ${theme.animations.timingFunction},
    box-shadow ${theme.animations.duration} ${theme.animations.timingFunction};
  will-change: color, background-color, border-color, box-shadow;

  ${({ variant, ...restProps }) => buttonVariants[variant](restProps)}
  ${({ size = 'base' }) => buttonSizes[size]}

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${StyledDropdownMenu} li &:focus {
    box-shadow: none;
  }
`

const StyledButtonIconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonIconWrapper = styled.span`
  padding-right: ${theme.spacing[1]}rem;
`

const StyledButtonContent = styled.span``

export {
  buttonVariants,
  StyledButton,
  StyledButtonIconContainer,
  StyledButtonIconWrapper,
  StyledButtonContent
}
