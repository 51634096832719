const colors = {
  grey: {
    100: '#F7F8FA',
    200: '#EBECF3',
    300: '#E0E2EC',
    400: '#DCDCE2',
    500: '#9C9EAC',
    700: '#4F5162',
    900: '#090F47'
  },
  blue: {
    100: '#EDF1FE',
    300: '#C5D1FF',
    500: '#3A62F2',
    700: '#3153CC',
    900: '#2843A6'
  },
  lightBlue: {
    100: '#E3F2FF',
    300: '#ABD7FF',
    500: '#2692F5',
    // TODO: get some "official" light blue shades
    700: '#1E75C6',
    900: '#2843A6'
  },
  orange: {
    100: '#FFF1E9',
    300: '#FFE0CE',
    500: '#F16415',
    700: '#CC5512',
    900: '#A6450E'
  },
  red: {
    100: '#FFEDED',
    300: '#FFD3D3',
    500: '#F23A3A',
    700: '#CC3131',
    900: '#A62828'
  },
  green: {
    100: '#D9F4ED',
    300: '#BEE9DD',
    500: '#04A378',
    700: '#037D5D',
    900: '#025740'
  },
  black: '#000000',
  white: '#FFFFFF'
} as const

export default colors
