import styled from 'styled-components'
import theme from 'theme'

const StyledInputLabel = styled.label<{ disabled?: boolean }>`
  font-size: ${theme.fontSizes.subtitle1}rem;
  color: ${(props) =>
    props.disabled ? theme.colors.grey[400] : theme.colors.grey[700]};
  line-height: 1.8rem;
  margin-bottom: ${theme.spacing[0]}rem;
  display: flex;
  justify-content: space-between;
`

export default StyledInputLabel
