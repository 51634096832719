import styled, { keyframes } from 'styled-components'

const spinningAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

interface ILoadingRingProps {
  size: string
}

const LoadingRing = styled.div<ILoadingRingProps>`
  display: inline-block;
  position: relative;

  width: ${(props) => props.size};
  height: ${(props) => props.size};
`

interface IAnimatedPartProps {
  size: string

  color?: string
}

const PART_SIZE_FACTOR = 0.8
const PART_LINE_SIZE_FACTOR = 0.1

const AnimatedPart = styled.div<IAnimatedPartProps>`
  box-sizing: border-box;
  display: block;
  position: absolute;

  border-style: solid;
  border-radius: 50%;
  animation: ${spinningAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props) => props.color ?? 'currentColor'} transparent
    transparent transparent;

  width: calc(${(props) => props.size} * ${PART_SIZE_FACTOR});
  height: calc(${(props) => props.size} * ${PART_SIZE_FACTOR});
  margin: calc(${(props) => props.size} * ${PART_LINE_SIZE_FACTOR});
  border-width: calc(${(props) => props.size} * ${PART_LINE_SIZE_FACTOR});

  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`

export { LoadingRing, AnimatedPart }
