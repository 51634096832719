const EyeIcon = () => {
  return (
    <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Eye</title>
      <path
        d='M9.871 11.982a2.055 2.055 0 104.11 0 2.055 2.055 0 00-4.11 0zm10.023-.473C18.154 7.844 15.525 6 12 6c-3.527 0-6.154 1.844-7.894 5.51a1.107 1.107 0 000 .945c1.74 3.665 4.369 5.509 7.894 5.509 3.527 0 6.154-1.844 7.894-5.51a1.106 1.106 0 000-.945zm-7.967 3.702a3.23 3.23 0 110-6.459 3.23 3.23 0 010 6.46z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EyeIcon
