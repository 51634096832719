import apiClient from 'api/apiClient'
import { IProtectedEndpointParams } from 'api/types'
import RequestException from 'errors/RequestException'
import { useQuery, UseQueryOptions } from 'react-query'

import { IAdmin } from './types'

const fetchMyProfile = async ({ token }: IProtectedEndpointParams) => {
  return await apiClient
    .get('admins/me', {
      headers: {
        Authorization: token
      }
    })
    .json<IAdmin>()
}

export interface IseMyProfileQueryOptions
  extends UseQueryOptions<IAdmin, RequestException> {
  token: string | null
}

/**
 * Can't use the `useAuthContext` in order to get `sessionToken` here as this query is being used
 * inside the AuthContextProvider
 */
export const useMyProfileQuery = ({
  token,
  ...options
}: IseMyProfileQueryOptions) => {
  return useQuery<IAdmin, RequestException>(
    'my-profile',
    async () => await fetchMyProfile({ token: token ?? '' }),
    {
      enabled: Boolean(token),
      ...options
    }
  )
}
